
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import Camera from "simple-vue-camera";

interface TrazabilidadDetails {
  reservaId: string|string[];
  trazabilidadId: string | undefined | null;
  esInicio:boolean,
  kilometrosActuales: number | undefined | null;
  nivelCombustible: string | undefined | null;
  limpieza: string | undefined | null;
  imagenKilometros: string | undefined | null;
  imagenCombustible: string | undefined | null;
  imagenFrontal: string | undefined | null;
  imagenTrasero: string | undefined | null;
  imagenDerecha: string | undefined | null;
  imagenIzquierda: string | undefined | null;
  imagenLimpieza: string | undefined | null;
  imagenes: Array<any> | undefined | null;
}

export default defineComponent({
  name: "dashboard",
  components: {
    ErrorMessage,
    Field,
    Form,
    Camera
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const submitButton1 = ref<HTMLElement | null>(null);
    const imagenes = ref<any>();
    const mostrarNiveles = ref(false);
    const imagen_kilometros = ref<File>();
    const imagen_combustible = ref<File>();
    const imagen_limpieza = ref<File>();
    const imagen_frontal = ref<File>();
    const imagen_trasero = ref<File>();
    const imagen_derecha = ref<File>();
    const imagen_izquierda = ref<File>();
    const visibleBottom = ref(false);
    const snapshotUrl = ref('');
    const snapshotBlob = ref();
    const type = ref('');
    const id = route.params.id;
    console.log('id ' + route.params.id);
    const resolution = ref({ width: 0, height: 800  });
    const trazabilidadValidator = Yup.object().shape({
      kilometrosActuales: Yup.string().required("Es obligatorio").label("Kilometros"),
      nivelCombustible: Yup.string().required("Es obligatorio").label("Combustible"),
      limpieza: Yup.string().required("Es obligatorio").label("Limpieza"),
      //fechaDevolucion: Yup.date().required("Es obligatorio").label("Fecha de devolucion"),
      //promocion: Yup.string().required("Es obligatorio").label("Promocion"),
      //puntos: Yup.number().required("Es obligatorio").label("Puntos"),
    });
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      obtenerInformacionReserva();
      obtenerReserva();
      window.addEventListener('resize', handleResize);
      handleResize();
    });
    const trazabilidadDetails = ref<TrazabilidadDetails>({
      reservaId:id, 
      trazabilidadId:null,
      kilometrosActuales: null,
      nivelCombustible: "",
      limpieza: "",
      imagenKilometros: null,
      imagenCombustible: "",
      imagenLimpieza: "",
      imagenFrontal: "",
      imagenTrasero: "",
      imagenDerecha: "",
      imagenIzquierda: "",
      imagenes:null,
      esInicio:false
    });
    const handleResize = () => {
        //resolution.value.width = window.innerWidth;
        //resolution.value.height = window.innerHeight;
        resolution.value.width = window.innerHeight;
        resolution.value.height = window.innerWidth;
    };

    
    const saveChanges = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        
        let formData = new FormData();
        if(imagen_kilometros.value)
          formData.append("kilometros", imagen_kilometros.value);
        if(imagen_combustible.value)
          formData.append("combustible", imagen_combustible.value);
        if(imagen_limpieza.value)
          formData.append("limpieza", imagen_limpieza.value);
        if(imagen_frontal.value)
          formData.append("frontal", imagen_frontal.value);
        if(imagen_trasero.value)
          formData.append("trasero", imagen_trasero.value);
        if(imagen_derecha.value)
          formData.append("derecha", imagen_derecha.value);
        if(imagen_izquierda.value)
          formData.append("izquierda", imagen_izquierda.value);
        imagenes.value?.forEach((imagen) => {
            formData.append("galeria", imagen);
        });
        trazabilidadDetails.value.reservaId = route.params.id;
        formData.append("trazabilidad", JSON.stringify(trazabilidadDetails.value));

        store
          .dispatch(Actions.INS_TRAZABILIDAD, {
            reservaId: id,
            formData,
          })
          .then(() => {
            submitButton1.value?.removeAttribute("data-kt-indicator");
            router.push({ name: "ride" });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getReservaErrors);
            Swal.fire({
              text: store.getters.getReservaErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    };
    
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const currentReserva = computed(() => {
      return store.getters.currentReserva;
    });
    const sucursales = computed(() => {
      return store.getters.allSucursales;
    });
    const obtenerReserva = () => {
      store
        .dispatch(Actions.GET_TRAZABILIDAD_TERMINO, id)
        .then(() => {
          loading.value = false;
          const trazabilidad = store.getters.currentTrazabilidad;
          trazabilidadDetails.value = {
            reservaId: trazabilidad.reservaId,
            trazabilidadId:trazabilidad.trazabilidadId,
            kilometrosActuales:trazabilidad.kilometrosActuales,
            nivelCombustible: trazabilidad.nivelCombustible,
            limpieza: trazabilidad.limpieza,
            imagenKilometros: trazabilidad.imagenKilometros,
            imagenCombustible: trazabilidad.imagenCombustible,
            imagenLimpieza: trazabilidad.imagenLimpieza,
            imagenFrontal: trazabilidad.imagenFrontal,
            imagenTrasero: trazabilidad.imagenTrasero,
            imagenDerecha: trazabilidad.imagenDerecha,
            imagenIzquierda: trazabilidad.imagenIzquierda,
            imagenes: trazabilidad.imagenes,
            esInicio:true
          };
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getReservaErrors);
        });
    };
    const obtenerInformacionReserva = () => {
      store
        .dispatch(Actions.GET_RESERVA, id)
        .then(() => {
          loading.value = false;
          if(store.getters.currentReserva.esPropio!=true){
            mostrarNiveles.value = true;
            console.log('mostrarNiveles');
          } else {
            console.log('avanzar');
            let formData = new FormData();    
            trazabilidadDetails.value.reservaId = route.params.id;
            trazabilidadDetails.value.esInicio = true;
            formData.append("trazabilidad", JSON.stringify(trazabilidadDetails.value));
            store
              .dispatch(Actions.INS_TRAZABILIDAD, {
                reservaId: id,
                formData,
              })
              .then(() => {
                router.push({ name: "ride" });
              })
              .catch(() => {
                const [error] = Object.keys(store.getters.getReservaErrors);
                Swal.fire({
                  text: store.getters.getReservaErrors[error],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Intentar nuevamente!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              });
          }
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getReservaErrors);
        });
    };

    const fullscreenZIndex = ref(0);
    const view = ref("video");
    const camera = ref<InstanceType<typeof Camera>>();

    // Use camera reference to call functions
    const snapshot = async () => {
        const blob = await camera.value?.snapshot();
        snapshotBlob.value = blob;
        // To show the screenshot with an image tag, create a url
        const url = URL.createObjectURL(blob);
        snapshotUrl.value = url;
        view.value="snapshot";
    }
    const closeSnapshot = () => {
        view.value="video";
    };
    const confirmSnapshot = async () => {
      console.log('type.value' + type.value);
        let archivo;
        switch (type.value) {
          case "combustible":
            //imagen_combustible.value = new File(archivo, "combustible.png", {type: "", lastModified: Date.now()});
            imagen_combustible.value = blobToFile(snapshotBlob.value, "combustible.png");
            break;
          case "limpieza":
            imagen_limpieza.value =  blobToFile(snapshotBlob.value, "limpieza.png");
            break;
          case "kilometros":
            console.log('quedo ' + snapshotBlob.value?.type);
            //imagen_kilometros.value = new File(archivo, "kilometros.png", {type: "", lastModified: Date.now()});
            imagen_kilometros.value =  blobToFile(snapshotBlob.value, "kilometros.png");
            break;
          case "frontal":
            imagen_frontal.value =  blobToFile(snapshotBlob.value, "frontal.png");
            break;
          case "trasero":
            imagen_trasero.value =  blobToFile(snapshotBlob.value, "trasero.png");
            break;
          case "derecha":
            imagen_derecha.value =  blobToFile(snapshotBlob.value, "derecha.png");
            break;
          case "izquierda":
            imagen_izquierda.value =  blobToFile(snapshotBlob.value, "izquierda.png");
            break;
        }
        view.value="video";
        visibleBottom.value=false;
    };

    /**/
    const blobToFile = (theBlob: Blob, fileName:string): any => {//File => {
      var b: any = theBlob;
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      b.lastModifiedDate = new Date();
      b.name = fileName;
      return theBlob;
      //return <File>theBlob;
    };

    const dataURItoBlob = async (dataURI) => {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    };

    const loading = ref(true);
    return {
      loading,
      currentUser,
      sucursales,
      submitButton1,
      saveChanges,
      trazabilidadDetails,
      trazabilidadValidator,
      currentReserva,visibleBottom,
      camera,snapshotUrl,id,snapshotBlob,imagen_kilometros,imagen_combustible,imagen_limpieza,
      imagen_frontal,imagen_trasero,imagen_derecha,imagen_izquierda,mostrarNiveles,
      snapshot, fullscreenZIndex,view,closeSnapshot, type, confirmSnapshot, resolution
    };
  },
});
